
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,Card,InputGroup,Collapse  } from 'react-bootstrap';
import "./cadreRegistration.css";
import { withRouter,Link } from "react-router-dom";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import loader from '../../images/loader.gif';


//const fetch = require('node-fetch');

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </components.DropdownIndicator>
        )
    );
  };

class ChangeMobileNoOTPValidation extends Component {
    constructor(props) {
        super(props)
        

        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
        }
    }

    componentDidMount() {
        
       
    }
    render() {
        
       
        return (
            <fieldset class=""><legend>{this.props.stateData.englishSelected== true ? "Mobile No & Validate OTP" : "ఫోన్ నెంబర్ & OTP"}  </legend>  
            <Row>
                <Col className='mt-2'>
                    <Form>
                        <Form.Group className="" controlId="formMobileId">
                            <Form.Label className='f-12 fw-bold'>{this.props.stateData.englishSelected== true ? "Mobile No" : "ఫోన్ నెంబర్"} :<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.props.stateData.validateCaptureMobileNo}</span>
                            {
                                this.props.stateData.getOTPLoadingSymbol == "show" ?
                                (<Image src={loader} alt="loader" className='get_otp_loading_symbol_cls22'></Image>)
                                :
                                ("")
                            } 
                            </Form.Label>
                            <Row>
                                {
                                    this.props.stateData.isSkipOTP == "N" ?
                                    (
                                        <Col className='width_90_static_CR'> 
                                            <Form.Control type="text" name="captureMobileNo" disabled={this.props.stateData.mobileNoDisabled} className='form-control-sm' maxLength={10} value={this.props.stateData.captureMobileNo}  placeholder="Enter Mobile No" onKeyUp={(e) => this.props.handleOnchangeAllowNumbersInputBox(e)}  onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                        </Col>
                                    )
                                    :
                                    (
                                        <Col className=''> 
                                            <Form.Control type="text" name="captureMobileNo"  className='form-control-sm' maxLength={10} value={this.props.stateData.captureMobileNo}  placeholder="Enter Mobile No" onKeyUp={(e) => this.props.handleOnchangeAllowNumbersInputBox(e)}  onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                        </Col>
                                    )
                                }
                                
                            
                                {
                                    this.props.stateData.isSkipOTP == "N" ?
                                    (
                                        <Col className='width_10_static_CR'> 
                                    
                                            <>

                                            {
                                                this.props.stateData.OTPSentStatus == "Fail" || this.props.stateData.OTPSentStatus == "" ?
                                                (
                                                    <>
                                                        <span variant="dark" className='f-12 ml-0 span_button' onClick={() => this.props.getMobileNoOTP()}>GET OTP
                                                            
                                                        </span> 
                                                    </>
                                                )
                                                :
                                                ("")
                                            }

                                            {
                                                this.props.stateData.captureMobileNo != ""  ?
                                                (<span variant="dark" className='f-12 ml-6 span_button' onClick={() => this.props.changeMobileNo()}>Edit MobileNo</span> )
                                                :
                                                (
                                                    ""
                                                )
                                            }
                                            </>
                                        </Col>
                                        
                                        
                                    )
                                    :
                                    ("")
                                }
                                

                                {
                                    this.props.stateData.isSkipOTP == "N" ?
                                    (
                                        <>
                                        {
                                            this.props.stateData.OTPSentStatus == "Fail" || this.props.stateData.OTPSentStatus == "" ?
                                            (
                                                <>
                                                    <h6 className='f-12 color_red'>{this.props.stateData.smsSentMessage}</h6>
                                                </>
                                            )
                                            :
                                            ("")
                                        }
                                    
                                        </>
                                    )
                                    :
                                    ("")
                                
                                }
                                
                               
                            </Row>
                        </Form.Group>  
                    </Form>

                    {
                        this.props.stateData.isSkipOTP == "N" ?
                        (
                            <Form>
                                <Row>
                                    
                                    <Col className=''>
                                    <InputGroup className="mt-3">
                                                <InputGroup.Text id="basic-addon1" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>{this.props.stateData.englishSelected== true ? "OTP NO" : "OTP నెంబర్"} <span className='color_red f-14 ms-1'>*</span>  </InputGroup.Text>
                                                <Form.Control type="text" name="captureOTPNO" maxLength={6} disabled={this.props.stateData.OTPVerifiedDisabledButton} className='form-control-sm' value={this.props.stateData.captureOTPNO}  placeholder="Enter OTP No" onChange={(e) => this.props.handleOnchangeInputBox(e)}/>
                                    </InputGroup>
                                    <h6 className='color_red f-12 mt-2 font-weight-bold'>{this.props.stateData.validateCaptureOTP}</h6>
                                    </Col>
                                    
                                </Row>
                                
                                    <Row>
                                        <Col>
                                            {
                                                this.props.stateData.OTPSentStatus == "Success"?
                                                (
                                                    <>
                                                        <h6 className='mt-2 f-12 color_green line_height_20'>{this.props.stateData.smsSentMessage} : <b >{this.props.stateData.captureMobileNo} </b></h6>
                                                        
                                                        {
                                                            this.props.stateData.otpVerifiedMessageStatus != "Verified" || this.props.stateData.otpVerifiedMessageStatus == "" ?
                                                            (
                                                                <div className="countdown-text">
                                                                    {this.props.stateData.seconds > 0 || this.props.stateData.minutes > 0 ? (
                                                                    <h6 className='mt-2 f-14'>
                                                                        {this.props.stateData.englishSelected== true ? "Time Remaining" : "మిగిలిన సమయం"} : {this.props.stateData.minutes < 10 ? `0${this.props.stateData.minutes}` : this.props.stateData.minutes}:
                                                                        {this.props.stateData.seconds < 10 ? `0${this.props.stateData.seconds}` : this.props.stateData.seconds}
                                                                    </h6>
                                                                    ) : (
                                                                    <h6 className='mt-2 f-12'>{this.props.stateData.englishSelected== true ? "Didn't recieve code?" : "కోడ్ అందలేదా?"} </h6>
                                                                    )}
                                                                    {
                                                                        this.props.stateData.resendOTPLoadingSymbol == "show" ?
                                                                        (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls'></Image>)
                                                                        :
                                                                        ("")
                                                                    } 
                                                                    <Button variant="dark" className='f-12 float-right mt-2' 
                                                                    disabled={this.props.stateData.seconds > 0 || this.props.stateData.minutes > 0}
                                                                    style={{
                                                                        color: this.props.stateData.seconds > 0 || this.props.stateData.minutes > 0 ? "#DFE3E8" : "#fff",
                                                                    }} onClick={this.props.resendOTPNew}  >Resend OTP</Button>
                                                                    
                                                                </div>
                                                            )
                                                            :
                                                            ("")
                                                        }
                                                    
                                                        
                                                    </>
                                                )
                                                :
                                                ("")
                                            }
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            {
                                                    this.props.stateData.otpLoadingSymbol == "show" ?
                                                    (<Image src={loader} alt="loader" className='loader_cls_otp ms-2'></Image>)
                                                    :
                                                    (
                                                        this.props.stateData.otpVerifiedMessageStatus == "Verified" ?
                                                        (

                                                            <h6 class="color_green fw-bold f-14  text-center mt-2"><i class="fa-solid fa-check mr-2"> </i>{this.props.stateData.englishSelected== true ? "OTP verified successfully" : "OTP విజయవంతంగా ధృవీకరించబడింది"} </h6>
                                                            
                                                        )
                                                        :
                                                        (
                                                            this.props.stateData.otpVerifiedMessageStatus == "NotVerified" ?
                                                            (<h6 class="color_red fw-bold f-14 text-center mt-2"><i class="fa-solid fa-check mr-2"> </i>{this.props.stateData.englishSelected== true ? "Not Verified,Please Enter Valid OTP" : "దయచేసి చెల్లుబాటు అయ్యే OTPని నమోదు చేయండి"} </h6>)
                                                            :
                                                            ("")
                                                            
                                                        
                                                        )
                                                )
                                            }
                                        </Col>
                                    </Row>
                                
                            </Form>
                        )
                        :
                        ("")
                    }
                    

                    
                </Col>
            </Row>
        </fieldset>
        )
    }
  }
  
  export default withRouter(ChangeMobileNoOTPValidation);
  