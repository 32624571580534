import React, { Component } from "react";
import {Container, Row, Col} from 'react-bootstrap';
import { getAPICallData } from '../services/MemberService';
import { getAPICallDataForOnlyBSA } from '../services/MemberService';
import { withRouter,Link } from "react-router-dom";
import "./NavBar.css"
import TDPLOGO from '../images/cbn.png';
import HEADER from '../images/header.png';
//import  {getUserAccessData}  from '../components/UserAccessFile';
import MenuItem from "./MenuItem";
import * as Cookies from "es-cookie";
import moment from "moment";
import { getAPICallDataForCadreSaving } from '../services/MemberService';


const consMLCIdsArr = [163,157,156,307,155,147,308,159,153,146,160,310,152,309,303,304,305,149,306,172,366,181,174,173,167,179,178,177,180,169,170,171,176,168,194,193,184,185,187,327,328,182,329,330,196,331,195,191,192,186,210,215,206,211,217,213,216,209,212,312,311,199,208,214,207,203,205]

class NavBar extends Component {
  constructor(props) {
    super(props)

      this.state = {
        sidebar:false,
        first_name:  window.User !=undefined ? window.User.first_name : "",
        last_name:  window.User !=undefined ? window.User.last_name : "" ,
        chatID: window.User !=undefined ? window.User.chatID : "5129193582",
        accessName:"",
        verifyHouseAccessStatus:"",
        userAccessListArr:[],
        accessNameNavigation:"",
        attrVerfiDtsCheck:[],
        BGAccess:"",
        BGAccessForOtherRols:"",
        roleName:"",
        pageLoading:"hide",
        isVolunteer:"",
        isVolunteermobileNo:"",
        currentIndex: 0,
        images: [
          'https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/banner1.png',
          'https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/banner2.png',
          'https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/banner3.png',
          'https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/banner4.png',
          'https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/banner5.png',
          'https://imagesearch-projectkv.s3.amazonaws.com/STATIC_CONTENT/BG/IMAGES/banner6.png',
        ]
      }
  }


  showSidebar = () => {
    this.setState((prevState) => ({
      sidebar: !prevState.sidebar
    })); 
    
  } 
  

  componentDidMount() {
    localStorage.clear();
    this.getUserAccessBoothsForVerification();
    this.getUserRoleDetailsByUserId();
    this.getMobileNoByChatId();
    this.intervalId = setInterval(this.nextSlide, 3000); // 3000ms = 3 seconds

   /* getUserAccessData().then(data => {
          console.log("data",data)
    });*/
    this.setState({pageLoading:"show"})

    const userAccessObj =  
        JSON.stringify({ 
          "username" : "bsa_api",
          "password" : "9D#Jcx!i5B$z@ym4d7K2f",
          "chatId" :this.state.chatID
        }
      ); 

      //getUserAccessLocationDetails
      getAPICallDataForOnlyBSA(userAccessObj,"getUserAccessLocationDetails")
          .then((res) => res.json())
          .then((userAccessList) => {
              this.setState({ accessName: "",pageLoading:"hide"});
              if(userAccessList != null && userAccessList.status === "Success"){

               // this.getUserRoleAccess();
                  this.setState({ userAccessListArr:userAccessList  });
                  localStorage.setItem('userAccessList', JSON.stringify(userAccessList));

                  if(userAccessList.unitList.length == 0  &&  userAccessList.clusterList.length == 0 && userAccessList.boothList.length == 0  &&  userAccessList.constituencyList.length == 0 && userAccessList.kssList.length == 0	 ){
                    this.setState({ accessName:"NoAccess",BGAccessForOtherRols:"No",roleName:""})
                    localStorage.setItem('accessName', this.state.accessName);
                    localStorage.setItem('roleName', this.state.roleName);
                    localStorage.setItem('accessNameNavigation', this.state.accessNameNavigation);
                    Cookies.set("role", this.state.roleName);
                  }

                    else if(userAccessList.unitList.length==0 && userAccessList.clusterList.length==0 && userAccessList.boothList.length > 1 && userAccessList.mandalList.length== 0 && userAccessList.kssList.length == 0){
                        if(userAccessList.constituencyList !=null && userAccessList.constituencyList.length>0){
                            if(userAccessList.constituencyList.length == 1){
                                const cons_name = userAccessList.constituencyList.filter((item,index) => index === 0).map(clist => clist.name);
                                this.setState({roleName:"constituency",BGAccessForOtherRols:"yes", accessName:cons_name+" Constituency",verifyHouseAccessStatus:"yes",accessNameNavigation:cons_name+" Constituency"})
                                localStorage.setItem('accessName', this.state.accessName);
                                localStorage.setItem('roleName', this.state.roleName);
                                Cookies.set("role", this.state.roleName);
                                localStorage.setItem('accessNameNavigation', this.state.accessNameNavigation);
                            }else{
                                this.setState({roleName:"constituency",BGAccessForOtherRols:"yes",  accessName:"Constituency",verifyHouseAccessStatus:"yes",accessNameNavigation:"Constituency"})
                                localStorage.setItem('accessName', this.state.accessName);
                                localStorage.setItem('roleName', this.state.roleName);
                                Cookies.set("role", this.state.roleName);
                                localStorage.setItem('accessNameNavigation', this.state.accessNameNavigation);
                            }
                        }
                      
                    }
                      else if(userAccessList.clusterList!= null && userAccessList.clusterList.length>0){
                          const cons_name = userAccessList.constituencyList.filter((item,index) => index === 0).map(clist => clist.name);
                          const clusterName = userAccessList.clusterList.filter((clist,index) => index === 0).map(clist => clist.name);
                          this.setState({roleName:"cluster",BGAccessForOtherRols:"yes", accessName:clusterName,verifyHouseAccessStatus:"yes",accessNameNavigation:cons_name+" > "+clusterName + ""})
                          localStorage.setItem('accessName', this.state.accessName);
                          localStorage.setItem('roleName', this.state.roleName);
                          Cookies.set("role", this.state.roleName);
                          localStorage.setItem('accessNameNavigation', this.state.accessNameNavigation);
                      }
                      else if(userAccessList.unitList!= null && userAccessList.unitList.length>0){
                          const cons_name = userAccessList.constituencyList.filter((item,index) => index === 0).map(clist => clist.name);
                          const clusterName = userAccessList.unitList.filter((clist,index) => index === 0).map(clist => clist.name);
                          this.setState({roleName:"unit",BGAccessForOtherRols:"yes", accessName:clusterName,verifyHouseAccessStatus:"yes",accessNameNavigation:cons_name+" > "+clusterName + ""})
                          localStorage.setItem('accessName', this.state.accessName);
                          localStorage.setItem('roleName', this.state.roleName);
                          Cookies.set("role", this.state.roleName);
                          localStorage.setItem('accessNameNavigation', this.state.accessNameNavigation);
                      }
                      else if(userAccessList.unitList.length == 0  &&  userAccessList.clusterList.length == 0 && userAccessList.mandalList.length == 0 &&  userAccessList.kssList.length == 0 && userAccessList.boothList.length == 1){
                          const cons_name = userAccessList.constituencyList.filter((item,index) => index === 0).map(clist => clist.name);
                          const booth_Name = userAccessList.boothList.filter((item,index) => index === 0).map(blist => blist.name);
                          this.setState({roleName:"booth",BGAccessForOtherRols:"yes", accessName:"Booth - "+booth_Name +" Incharge",verifyHouseAccessStatus:"yes",accessNameNavigation:cons_name+" > Booth - "+booth_Name})
                          localStorage.setItem('accessName', this.state.accessName);
                           localStorage.setItem('roleName', this.state.roleName);
                           Cookies.set("role", this.state.roleName);
                           localStorage.setItem('accessNameNavigation', this.state.accessNameNavigation);
                      }else if((userAccessList.mandalList !=null && userAccessList.mandalList.length>0) && userAccessList.boothList !=null && userAccessList.boothList.length>0){
                          const cons_name = userAccessList.constituencyList.filter((item,index) => index === 0).map(clist => clist.name);
                          const mandal_Name = userAccessList.mandalList.filter((item,index) => index === 0).map(blist => blist.name);
                          this.setState({roleName:"Mandal",BGAccessForOtherRols:"yes", accessName:"Mandal - "+mandal_Name +" Incharge",verifyHouseAccessStatus:"yes",accessNameNavigation:cons_name+" > Mandal - "+mandal_Name})
                          localStorage.setItem('accessName', this.state.accessName);
                           localStorage.setItem('roleName', this.state.roleName);
                           Cookies.set("role", this.state.roleName);
                           localStorage.setItem('accessNameNavigation', this.state.accessNameNavigation);
                      }else if((userAccessList.kssList !=null && userAccessList.kssList.length>0) && userAccessList.boothList !=null && userAccessList.boothList.length>0){
                        const booth_Name = userAccessList.boothList.filter((item,index) => index === 0).map(clist => clist.name);
                        const kss_Name = userAccessList.kssList.filter((item,index) => index === 0).map(blist => blist.name);
                        this.setState({roleName:"booth",BGAccessForOtherRols:"yes", accessName:"Booth - "+booth_Name+" >  "+kss_Name +" Incharge",verifyHouseAccessStatus:"yes",accessNameNavigation:"Booth - "+booth_Name+" >  "+kss_Name+" Incharge"})
                        localStorage.setItem('accessName', this.state.accessName);
                         localStorage.setItem('roleName', this.state.roleName);
                         Cookies.set("role", this.state.roleName);
                         localStorage.setItem('accessNameNavigation', this.state.accessNameNavigation);
                    }
                     
              }else{
                this.setState({ accessName:"NoAccess",BGAccessForOtherRols:"No",roleName:""})
                localStorage.setItem('accessName', this.state.accessName);
                localStorage.setItem('roleName',this.state.roleName);
                Cookies.set("role", this.state.roleName);
                localStorage.setItem('accessNameNavigation', this.state.accessNameNavigation);
              }
              

      });      
  }

  componentWillUnmount() {
    // Cleanup the interval to prevent memory leaks
    clearInterval(this.intervalId);
  }

  nextSlide = () => {
    const { currentIndex, images } = this.state;
    // Update the current index to show the next image
    const nextIndex = (currentIndex + 1) % images.length; // Loop back to the first image when reaching the last
    this.setState({ currentIndex: nextIndex });
  };

  getMobileNoByChatId = () => {
        
    const obj =  
    JSON.stringify({ 
       "chatId" :this.state.chatID,
    }); 

    getAPICallDataForOnlyBSA(obj,"getMobileNoByChatId")
    .then((res) => res.json())
    .then((data) => {
       if(data !=null && data.mobileNo !=null){
        this.getVolunteerDetailsByMobileNo(data.mobileNo);
       }else{
        this.setState({isVolunteer:""});
        localStorage.setItem('isVolunteerAccess', "");
        Cookies.set("isVolunteerAccess", "");
       }
    });
}

getVolunteerDetailsByMobileNo = (mobileNo) => {
  const obj =  
      JSON.stringify({ 
          "volunteerMobileNo" : mobileNo
      });

      getAPICallDataForCadreSaving(obj,"getVolunteerDetailsByMobileNo")
      .then((res) => res.json())
      .then((content) => {
          if(content !=null ){
              this.setState({isVolunteer:content.isVolunteer,isVolunteermobileNo:content.mobileNo}); 
              Cookies.set("isVolunteerAccess", content.isVolunteer);            
             }else{
              this.setState({isVolunteer:"",isVolunteermobileNo:""});
              Cookies.set("isVolunteerAccess", "");
             }
      });
}

  getUserAccessBoothsForVerification = () => {
    const roleObj =  
          JSON.stringify({ 
            "username" : "bsa_api",
            "password" : "9D#Jcx!i5B$z@ym4d7K2f",
            "chatId" :this.state.chatID
          }); 
          getAPICallData(roleObj,"attributes/getUserAccessBoothsForVerification")
          .then((res) => res.json())
          .then((roleList) => {
            if(roleList !=null){
              //console.log("dddddddddd",roleList)
              this.setState({ attrVerfiDtsCheck:roleList }); 
            }else{
              this.setState({ attrVerfiDtsCheck:[]}); 
            }
            
          }); 
  }


  getUserRoleDetailsByUserId = () => {
    const BGObj =  
          JSON.stringify({ 
            "username" : "bsa_api",
            "password" : "9D#Jcx!i5B$z@ym4d7K2f",
            "chatId" :this.state.chatID
          }); 
          getAPICallData(BGObj,"getUserRoleDetailsByUserId")
          .then((res) => res.json())
          .then((BGList) => {
            if(BGList !=null){
              Cookies.set("BGAccess", BGList.id);
              this.setState({ BGAccess:BGList }); 
            }else{
              this.setState({ BGAccess:""}); 
            }
            
          }); 
  }

  


  render() {
    const queryParams = new URLSearchParams(window.location.search);
    let navbarDisplayVal = queryParams.get('navbarDisplayPay');
    console.log("navbarDisplayVal",navbarDisplayVal);

    var navbarDisplayFinal="";

    if(navbarDisplayVal != undefined){
      navbarDisplayFinal = navbarDisplayVal;
    }else{
      navbarDisplayFinal = this.props?.location?.params?.navbarDisplay
    }

    const { currentIndex, images } = this.state;

    return (
        <>
          <section>
          <div className={navbarDisplayFinal == undefined || navbarDisplayFinal == "show" ? "" : "hideComponent"}>

              <div className="flex-header mt-1 bg_new_yellow_clr">
                  <div>
                    <Link to="#" className="menu-bars m_right_left_18">
                      <i className="fa-solid fa-bars color_red" onClick={this.showSidebar} ></i>
                    </Link>
                  </div>
                  <div>
                    <img src={require('../images/tdp_party_logo.png')} alt="cbn_support" className='width_190'></img>
                  </div>
                  <div>
                    <img src={require('../images/cbn.png')} alt="cbn_support" className='width_55'></img>
                    <img src={require('../images/lokesh_logo.png')} alt="cbn_support" className='width_65'></img>
                  </div>
                  
              </div>
              <img src={require('../images/new_banner.png')} alt="cbn_support" className='w-100 '></img>
              <div className="flex-header">
                <div>
                <img src={require('../images/main_banner.png')} alt="cbn_support" className='w-100 '></img>
                </div>
                <div>
                <img src={images[currentIndex]} alt="slide" className="slide-image w-100" />
                </div>
              </div>

                {/*<div className="height_bg">
                  <div className="flex-header mt-1">
                        <Link to="#" className="menu-bars">
                          <i className="fa-solid fa-bars color_red" onClick={this.showSidebar} ></i>
                        </Link>
                      
                      <h5 className="logo_font_size color_red f-18">Telugu Desam Party</h5>
                      <img className="center-auto" src={TDPLOGO} />
                    </div>
                    <img src={require('../images/TDP_JSP.png')} alt="cbn_support" className='header_img_align'></img>
                </div>*/}

                {
                    this.state.accessName != "NoAccess" ?
                    (<div className="bg_red color_white fw-bold f-14">{this.state.accessName} Details:</div>)
                    :
                    (<div className="bg_red color_white fw-bold f-14">Welcome</div>)
                }
                {
                      this.state.pageLoading == "show" ?
                      (
                          <div className="row">
                              <div className="col-sm-12 mt-3">
                                  <div class="spinner-border color_yellow loading-img-center mt-2"></div>
                              </div>
                          </div>
                      )
                      :
                      ("")
                  }
                  
              </div>
                  <div className="navbar">
                    <nav className={this.state.sidebar == true ? "nav-menu active border_left_right_top_radius z_index" : "nav-menu border_left_right_top_radius z_index"}>
                      <ul className="nav-menu-items scroll_navBar_cls">
                        <div className="d-flex p-2 bg_yellow_color border_left_right_top_radius">
                          <div className="flex-shrink-0">
                            <img src={TDPLOGO} alt="TDP_LOGO"/>
                          </div>
                          <div className="flex-grow-1 ms-3">
                              <span className="float-end" onClick={this.showSidebar}><Link to="#"><i className="fa-regular fa-circle-xmark color_black cross_icon_pos"></i></Link></span>
                              <h6 className="fw-bold">{this.state.first_name} {this.state.last_name}</h6>
                              {
                                this.state.BGAccess.id != "12" ?
                                ( <h6 className="f-14">{this.state.accessName == "NoAccess" ? "" : this.state.accessName}</h6>)
                                :
                                ("")
                              }
                            
                          </div>
                        </div>
                        
                        <Link to={{
                              pathname: "/dashboard",
                              params:{
                                navbarDisplay:"show",
                                userAccessList:this.state.userAccessListArr,
                                accessName:this.state.accessName,
                                roleName:this.state.roleName,
                                nameType:"",
                                accessNameNavigation:this.state.accessNameNavigation
                              }
                              }} onClick={() => { this.showSidebar()}}>
                              <MenuItem label="Home" subMenuItems={[]} icon="fa-solid fa-house" newBlock=""   />
                          </Link>

                          <Link to={{
                                pathname: "/cadre_registration",
                                      params: {
                                        navbarDisplay:"hide",
                                        //userAccessList:this.state.userAccessListArr,
                                      }
                                    }} onClick={() => { this.showSidebar()}}>
                                  <MenuItem label="MemberShip-New" subMenuItems={[]} icon="fa-solid fa-person-booth" newBlock="new"   />
                          </Link>
                          
                          <Link to={{
                                      pathname: "/referral_reg_details",
                                            params: {
                                              navbarDisplay:"hide",
                                            }
                                          }} onClick={() => { this.showSidebar()}}>
                                      <MenuItem label="Referral-REG-Details" subMenuItems={[]} icon="fa-solid fa-person-circle-plus" newBlock="new"   />
                          </Link>

                          
                            {
                              this.state.accessName != "NoAccess" ? 
                              (
                                this.state.userAccessListArr.status == "Success"  ?
                                ( 
                                  this.state.BGAccess.id == "1"  || this.state.BGAccess.id == "2"  || this.state.BGAccess.id == "3" || this.state.BGAccess.id == "4" || this.state.BGAccess.id == "27" || this.state.BGAccess.id == "19" ? 
                                  (
                                    <>
                                    <Link to={{
                                      pathname: "/pending_renewal_details",
                                            params: {
                                              navbarDisplay:"hide",
                                              userAccessList:this.state.userAccessListArr,
                                              accessName:this.state.accessName,
                                              roleName:this.state.roleName,
                                              boothId:"",
                                              enrollTypeStr:""
                                            }
                                          }} onClick={() => { this.showSidebar()}}>
                                      <MenuItem label="Pending Enrollments" subMenuItems={[]} icon="fa-solid fa-person-circle-xmark" newBlock="new"   />
                                    </Link>

                                    <Link to={{
                                      pathname: "/cadre_reg_dashboard",
                                            params: {
                                              navbarDisplay:"hide",
                                              //userAccessList:this.state.userAccessListArr,
                                              isVolunteermobileNo:this.state.isVolunteermobileNo
                                            }
                                          }} onClick={() => { this.showSidebar()}}>
                                      <MenuItem label="MemberShip-Dashboard" subMenuItems={[]} icon="fa-solid fa-person-booth" newBlock="new"   />
                                    </Link>
                                    </>
                                  )
                                  :
                                  ("")
                                )
                                :
                                ("")
                              )
                              :
                              ("")
                                
                          }
                         
                        

                          {
                            this.state.accessName != "NoAccess" ? 
                            (
                              this.state.userAccessListArr.status == "Success"  ?
                              (
                                this.state.BGAccess.id == "12" ?
                                (
                                  <>
                                  {/*<Link to={{
                                    pathname: "/volunteer_page",
                                    params: {
                                      navbarDisplay:"hide",
                                      userAccessList:this.state.userAccessListArr,
                                    }
                                  }} onClick={() => { this.showSidebar()}}>
                                    <MenuItem label="భవిష్యత్తుకు గ్యారెంటీ - నమోదు" subMenuItems={[]} icon="fa-solid fa-address-card"   />
                                </Link>*/}
                                  <Link to={{
                                        pathname: "/enrollment_dashboard",
                                        params: {
                                          navbarDisplay:"hide",
                                          volunteerDts:"",
                                          userAccessList:this.state.userAccessListArr,
                                          accessName:this.state.accessName,
                                          roleName:this.state.roleName
                                        }
                                      }} onClick={() => { this.showSidebar()}}>
                                        <MenuItem label="భవిష్యత్తుకు గ్యారెంటీ - DashBoard" subMenuItems={[]} icon="fa-solid fa-file-circle-plus" newBlock=""   />
                                      </Link>
                                  

                                  </>
                                )
                                :
                                (

                                  this.state.BGAccess.id == "11" ? 
                                  (
                                    this.state.attrVerfiDtsCheck.length > 0 ?
                                      (
                                        <>
                                              <Link to={{
                                                      pathname: "/voters_collections",
                                                      params: {
                                                        userAccessList:this.state.userAccessListArr,
                                                        attrVerfiDtsCheck:this.state.attrVerfiDtsCheck
                                                      }
                                                  }} onClick={() => { this.showSidebar()}}>
                                                  <MenuItem label="Attributes Verification" subMenuItems={[]} icon="fa-solid fa-file-circle-check" newBlock=""  />
                                              </Link>
                                              <Link to={{
                                                      pathname: "/voters_collections_dashboard",
                                                      params: {
                                                        userAccessList:this.state.userAccessListArr,
                                                        attrVerfiDtsCheck:this.state.attrVerfiDtsCheck
                                                      }
                                                  }} onClick={() => { this.showSidebar()}}>
                                                  <MenuItem label="Verification - Dashboard" subMenuItems={[]} icon="fa-solid fa-file-circle-check" newBlock=""  />
                                                </Link>
                                          </>
                                      )
                                      :
                                      ("")
                                  )
                                  :
                                  (

                                    this.state.BGAccess.id == "13" ?
                                    (
                                      <Link to={{
                                        pathname: "/first_time_voter_search",
                                        params:{
                                          userAccessList:this.state.userAccessListArr,
                                        }
                                      }} onClick={() => { this.showSidebar()}}>
                                      <MenuItem label="First Time Voter Search" subMenuItems={[]} icon="fa-solid fa-magnifying-glass" newBlock=""  />
                                    </Link>
                                    )
                                    :
                                    (

                                      this.state.BGAccess.id == "15" ?
                                      (
                                        
                                       <>
                                        <MenuItem
                                            label="DashBoard"
                                            icon="fa-solid fa-gauge"
                                            showSidebar={this.showSidebar}
                                            subMenuItems={
                                                                            
                                                [
                                                  
                                                  {name:"100 DAYS DTD CAMPAIGN",icon:"fa-solid fa-file-circle-plus",pathname:"/bsbg_dashboard_new",
                                                  navbarDisplay:"hide",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"bsbgNew",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                  

                                                  ]
                                            }
                                          />

                                          <Link to={{
                                              pathname: "/bsbg_shankharavam_new",
                                              params: {
                                                navbarDisplay:"hide",
                                                userAccessList:this.state.userAccessListArr,
                                                campaignId:7
                                              }
                                            }} onClick={() => { this.showSidebar()}}>
                                              <MenuItem label="100 DAYS DTD CAMPAIGN" subMenuItems={[]} icon="fa-solid fa-address-card" newBlock="new"   />
                                          </Link>
                                          
                                         
                                        </>
                                       
                                      )
                                      :
                                      (
                                        this.state.BGAccess.id == "16" ? 
                                        (
                                          <>
                                          <MenuItem
                                            label="DashBoard"
                                            icon="fa-solid fa-gauge"
                                            showSidebar={this.showSidebar}
                                            subMenuItems={
                                                                            
                                                [
                                                  
                                                 // {name:"Public OutReach",icon:"fa-solid fa-file-circle-plus",pathname:"/publicOutReach_dashboard",
                                                  //navbarDisplay:"hide",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"publicOutReach",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                   
                                                  {name:"Public OutReach",icon:"fa-solid fa-file-circle-plus",pathname:"/publicOutReach_dashboard_new",
                                                  navbarDisplay:"hide",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"publicOutReach2",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},

                                                  ]
                                            }
                                          />

                                         {/* <Link to={{
                                            pathname: "/bsbg_publicOutreach",
                                            params: {
                                              navbarDisplay:"hide",
                                              userAccessList:this.state.userAccessListArr,
                                            }
                                          }} onClick={() => { this.showSidebar()}}>
                                            <MenuItem label="Public Outreach" subMenuItems={[]} icon="fa-solid fa-file-pen"  newBlock="" />
                                        </Link>*/}

                                           <Link to={{
                                                pathname: "/publicOutReach_new",
                                                    params: {
                                                      navbarDisplay:"hide",
                                                      userAccessList:this.state.userAccessListArr,
                                                      campaignId:2
                                                    }
                                                  }} onClick={() => { this.showSidebar()}}>
                                                    <MenuItem label="Public OutReach" subMenuItems={[]} icon="fa-solid fa-address-card" newBlock="new"   />
                                            </Link>

                                            </>
                                        )
                                        :
                                        (
                                          this.state.BGAccess.id == "17" ? 
                                          (
                                            <>
                                              <MenuItem
                                                      label="DashBoard"
                                                      icon="fa-solid fa-gauge"
                                                      showSidebar={this.showSidebar}
                                                      subMenuItems={
                                                                                      
                                                          [
                                                            
                                                            {name:"Attendance",icon:"fa-solid fa-gauge",pathname:"/attendence_dashboard",
                                                            navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                          
                                                            ]
                                                      }
                                                    />

                                              <Link to={{
                                                  pathname: "/qr_attendence",
                                                  params: {
                                                    navbarDisplay:"show",
                                                    userAccessList:this.state.userAccessListArr,
                                                  }
                                                }} onClick={() => { this.showSidebar()}}>
                                                  <MenuItem label="Attendence" subMenuItems={[]} icon="fa-solid fa-clipboard-user" newBlock=""  />
                                            </Link>
                                          </>
                                          )
                                          :
                                          (
                                            this.state.BGAccess.id == "18" ? 
                                            (
                                              <>
                                                <MenuItem
                                                    label="DashBoard"
                                                    icon="fa-solid fa-gauge"
                                                    showSidebar={this.showSidebar}
                                                    subMenuItems={
                                                                                    
                                                        [
                                                          
                                                          {name:"100 DAYS DTD CAMPAIGN",icon:"fa-solid fa-file-circle-plus",pathname:"/bsbg_dashboard_new",
                                                          navbarDisplay:"hide",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"bsbgNew",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                          
                                                          //{name:"Public OutReach",icon:"fa-solid fa-file-circle-plus",pathname:"/publicOutReach_dashboard",
                                                         // navbarDisplay:"hide",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"publicOutReach",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                      
                                                          {name:"Public OutReach",icon:"fa-solid fa-file-circle-plus",pathname:"/publicOutReach_dashboard_new",
                                                          navbarDisplay:"hide",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"publicOutReach2",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                         

                                                         
                                                          ]
                                                    }
                                                  />

                                                <Link to={{
                                                    pathname: "/bsbg_shankharavam_new",
                                                    params: {
                                                      navbarDisplay:"hide",
                                                      userAccessList:this.state.userAccessListArr,
                                                      campaignId:7
                                                    }
                                                  }} onClick={() => { this.showSidebar()}}>
                                                    <MenuItem label="100 DAYS DTD CAMPAIGN" subMenuItems={[]} icon="fa-solid fa-address-card" newBlock="new"  />
                                                  </Link>

                                                  {/*<Link to={{
                                                      pathname: "/bsbg_publicOutreach",
                                                      params: {
                                                        navbarDisplay:"hide",
                                                        userAccessList:this.state.userAccessListArr,
                                                      }
                                                    }} onClick={() => { this.showSidebar()}}>
                                                      <MenuItem label="Public Outreach" subMenuItems={[]} icon="fa-solid fa-file-pen" newBlock=""  />
                                                  </Link>*/}

                                                    <Link to={{
                                                        pathname: "/publicOutReach_new",
                                                            params: {
                                                              navbarDisplay:"hide",
                                                              userAccessList:this.state.userAccessListArr,
                                                              campaignId:2
                                                            }
                                                          }} onClick={() => { this.showSidebar()}}>
                                                            <MenuItem label="Public OutReach" subMenuItems={[]} icon="fa-solid fa-address-card"  newBlock="new" />
                                                    </Link>
                                                  
                                                  </>

                                            )
                                            :
                                            (
                                              this.state.BGAccess.id == "20" ? 
                                              (
                                                <>
                                                <MenuItem
                                                    label="DashBoard"
                                                    icon="fa-solid fa-gauge"
                                                    showSidebar={this.showSidebar}
                                                    subMenuItems={
                                                                                    
                                                        [
                                                          
                                                          {name:"Public OutReach",icon:"fa-solid fa-file-circle-plus",pathname:"/publicOutReach_dashboard_new",
                                                          navbarDisplay:"hide",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"publicOutReach2",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                         
                                                          ]
                                                    }
                                                  />

                                                

                                                    <Link to={{
                                                        pathname: "/publicOutReach_new",
                                                            params: {
                                                              navbarDisplay:"hide",
                                                              userAccessList:this.state.userAccessListArr,
                                                              campaignId:2
                                                            }
                                                          }} onClick={() => { this.showSidebar()}}>
                                                            <MenuItem label="Public OutReach" subMenuItems={[]} icon="fa-solid fa-address-card" newBlock="new"  />
                                                    </Link>
                                                  
                                                  </>
                                              )
                                              :
                                              (
                                                
                                                this.state.BGAccess.id == "23" ?
                                                (
                                                  <>
                                                    <MenuItem
                                                        label="DashBoard"
                                                        icon="fa-solid fa-gauge"
                                                        showSidebar={this.showSidebar}
                                                        subMenuItems={
                                                                                        
                                                            [
                                                              
                                                              {name:"100 DAYS DTD CAMPAIGN",icon:"fa-solid fa-file-circle-plus",pathname:"/bsbg_dashboard_new",
                                                              navbarDisplay:"hide",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"bsbgNew",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                              

                                                              ]
                                                        }
                                                      />

                                                      <Link to={{
                                                          pathname: "/bsbg_shankharavam_new",
                                                          params: {
                                                            navbarDisplay:"hide",
                                                            userAccessList:this.state.userAccessListArr,
                                                            campaignId:7
                                                          }
                                                        }} onClick={() => { this.showSidebar()}}>
                                                          <MenuItem label="100 DAYS DTD CAMPAIGN" subMenuItems={[]} icon="fa-solid fa-address-card" newBlock="new"   />
                                                      </Link>
                                                      
                                                      </>
                                                )
                                                :
                                                (
                                                  this.state.BGAccess.id == "24" ? 
                                                  (
                                                    <Link to={{
                                                      pathname: "/booth_location_mapping",
                                                      params: {
                                                        navbarDisplay:"show",
                                                        userAccessList:this.state.userAccessListArr,
                                                      }
                                                    }} onClick={() => { this.showSidebar()}}>
                                                      <MenuItem label="Booth Location Mapping" subMenuItems={[]} icon="fa-solid fa-gauge" newBlock="new"   />
                                                  </Link>
                                                  )
                                                  :
                                                  (
                                                    this.state.BGAccess.id == "25" ? 
                                                    (
                                                      <Link to={{
                                                          pathname: "/send_voter_slip",
                                                                params: {
                                                                  navbarDisplay:"show",
                                                                  userAccessList:this.state.userAccessListArr,
                                                                }
                                                              }} onClick={() => { this.showSidebar()}}>
                                                            <MenuItem label="Voter Slip & Poll Management" subMenuItems={[]} icon="fa-solid fa-person-booth" newBlock="new"   />
                                                      </Link>
                                                    )
                                                    :
                                                    (
                                                      this.state.BGAccess.id == "26" ? 
                                                      (
                                                        <>
                                                          <Link to={{
                                                              pathname: "/cadre_registration",
                                                                    params: {
                                                                      navbarDisplay:"hide",
                                                                      //userAccessList:this.state.userAccessListArr,
                                                                    }
                                                                  }} onClick={() => { this.showSidebar()}}>
                                                                <MenuItem label="MemberShip-New" subMenuItems={[]} icon="fa-solid fa-person-booth" newBlock="new"   />
                                                        </Link>
                        
                                                        <Link to={{
                                                              pathname: "/cadre_reg_dashboard",
                                                                    params: {
                                                                      navbarDisplay:"hide",
                                                                      //userAccessList:this.state.userAccessListArr,
                                                                    }
                                                                  }} onClick={() => { this.showSidebar()}}>
                                                              <MenuItem label="MemberShip-Dashboard" subMenuItems={[]} icon="fa-solid fa-person-booth" newBlock="new"   />
                                                        </Link>
                                                      </>
                                                      )
                                                      :
                                                      (
                                                        this.state.BGAccess.id == "28" ? 
                                                        (
                                                          <>
                                                          {
                                                            consMLCIdsArr.some(item => item == this.state.userAccessListArr.constituencyList[0].id)
                                                            ?
                                                            (
                                                              <Link to={{
                                                                pathname: "/mlc_voters",
                                                                params:{
                                                                  userAccessList:this.state.userAccessListArr,
                                                                }
                                                              }} onClick={() => { this.showSidebar()}}>
                                                              <MenuItem label="MLC Voters Verification" subMenuItems={[]} icon="fa-solid fa-file-circle-plus" newBlock="new"  />
                                                            </Link>
                                                            )
                                                            :
                                                            ("")
                                                          }
                                                          </>
                                                        )
                                                        :
                                                        (
                                                          <>
                                                          <MenuItem
                                                          label="DashBoard"
                                                          icon="fa-solid fa-gauge"
                                                          showSidebar={this.showSidebar}
                                                          subMenuItems={
                                                                                          
                                                              [
                                                                {name:"100 DAYS DTD CAMPAIGN",icon:"fa-solid fa-file-circle-plus",pathname:"/bsbg_dashboard_new",
                                                                navbarDisplay:"hide",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"bsbgNew",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                                
                                                                //{name:"Public OutReach",icon:"fa-solid fa-file-circle-plus",pathname:"/publicOutReach_dashboard_new",
                                                                //navbarDisplay:"hide",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"publicOutReachNew",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                                
    
                                                                //{name:"Public OutReach",icon:"fa-solid fa-file-circle-plus",pathname:"/publicOutReach_dashboard",
                                                                //navbarDisplay:"hide",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"publicOutReach",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                                
    
                                                              // {name:"Attendance",icon:"fa-solid fa-gauge",pathname:"/attendence_dashboard",
                                                                //navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                                
    
                                                                {name:"భవిష్యత్తుకు గ్యారెంటీ",icon:"fa-solid fa-file-circle-plus",pathname:"/enrollment_dashboard",
                                                                navbarDisplay:"hide",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                                
                                                                {name:"KSS",icon:"fa-solid fa-magnifying-glass-chart",pathname:"/kss_dashboard",
                                                                navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                                
                                                                {name:"Verify House",icon:"fa-solid fa-gauge",pathname:"/verify_house_dashboard",
                                                                navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"verify_house",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                                
                                                                {name:"Programs-Activities",icon:"fa-solid fa-calendar-days",pathname:"/programs_dashboard",
                                                                navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,accessNameNavigation:this.state.accessNameNavigation,nameType:"",roleId:this.state.BGAccess.id},
                                                                
                                                                //{name:"Attributes Collection",icon:"fa-solid fa-file-circle-plus",pathname:"/attributes_collection_dashboard",
                                                                //navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,accessNameNavigation:this.state.accessNameNavigation,attrVerfiDtsCheck:this.state.attrVerfiDtsCheck,nameType:"attr_collection",roleId:this.state.BGAccess.id},
                                                                
                                                                //{name:"Deletions Voters Status",icon:"fa-solid fa-file-circle-plus",pathname:"/voter_modification_dashboard",
                                                                //navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,accessNameNavigation:this.state.accessNameNavigation,attrVerfiDtsCheck:this.state.attrVerfiDtsCheck,nameType:"attr_collection"},
                                                                
                                                                
                                                                //{name:"Attributes Verification",icon:"fa-solid fa-file-circle-plus",pathname:"/voters_collections_dashboard",
                                                                //navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,accessNameNavigation:this.state.accessNameNavigation,attrVerfiDtsCheck:this.state.attrVerfiDtsCheck,nameType:"attr_verification",roleId:this.state.BGAccess.id},
                                                                
                                                                {name:"Forms Data Report",icon:"fa-solid fa-file-circle-plus",pathname:"/forms_data_validation_report",
                                                                navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,accessNameNavigation:this.state.accessNameNavigation,attrVerfiDtsCheck:this.state.attrVerfiDtsCheck,nameType:"",roleId:this.state.BGAccess.id},
                                                                
                                                                  
    
                                                                ]
                                                          }
                                                        />
    
                                                        <MenuItem
                                                          label="Activities"
                                                          icon="fa-solid fa-gauge"
                                                          showSidebar={this.showSidebar}
                                                          subMenuItems={
                                                                                          
                                                              [
    
                                                                {name:"Booth Location Mapping",icon:"fa-solid fa-gauge",pathname:"/booth_location_mapping",
                                                                navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"booth_location_map",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
    
                                                              
                                                                {name:"Verify House",icon:"fa-solid fa-gauge",pathname:"/verify_house",
                                                                navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,nameType:"verify_house",accessNameNavigation:this.state.accessNameNavigation,roleId:this.state.BGAccess.id},
                                                                
                                                                {name:"Programs-Activities",icon:"fa-solid fa-calendar-days",pathname:"/events",
                                                                navbarDisplay:"hide",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,accessNameNavigation:this.state.accessNameNavigation,nameType:"",roleId:this.state.BGAccess.id},
                                                                
                                                                //{name:"Attributes Collection",icon:"fa-solid fa-file-circle-plus",pathname:"/attributes_collection",
                                                                //navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,accessNameNavigation:this.state.accessNameNavigation,attrVerfiDtsCheck:this.state.attrVerfiDtsCheck,nameType:"attr_collection",roleId:this.state.BGAccess.id},
                                                                
                                                                
                                                                //{name:"Attributes Verification",icon:"fa-solid fa-file-circle-plus",pathname:"/voters_collections",
                                                                //navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,accessNameNavigation:this.state.accessNameNavigation,attrVerfiDtsCheck:this.state.attrVerfiDtsCheck,nameType:"attr_verification",roleId:this.state.BGAccess.id},
                                                                
                                                                {name:"Voters Verification",icon:"fa-solid fa-file-circle-plus",pathname:"/voter_verification",
                                                                navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,accessNameNavigation:this.state.accessNameNavigation,attrVerfiDtsCheck:this.state.attrVerfiDtsCheck,nameType:"voters_verification",roleId:this.state.BGAccess.id},
                                                                
                                                                {name:"Sharing Activity",icon:"fa-solid fa-share-from-square",pathname:"/sharing_activity",
                                                                navbarDisplay:"show",userAccessList:this.state.userAccessListArr,accessName:this.state.accessName,roleName:this.state.roleName,accessNameNavigation:this.state.accessNameNavigation,attrVerfiDtsCheck:this.state.attrVerfiDtsCheck,nameType:"voters_verification",roleId:this.state.BGAccess.id},
                                                                
                                                                ]
                                                          }
                                                        />
                                                        {/*
                                                          this.state.BGAccess.id == "12" || this.state.BGAccessForOtherRols == "yes" ?
                                                          (
                                                            <Link to={{
                                                              pathname: "/volunteer_page",
                                                              params: {
                                                                navbarDisplay:"hide",
                                                                userAccessList:this.state.userAccessListArr,
                                                              }
                                                            }} onClick={() => { this.showSidebar()}}>
                                                              <MenuItem label="భవిష్యత్తుకు గ్యారెంటీ - నమోదు" subMenuItems={[]} icon="fa-solid fa-address-card"   />
                                                            </Link>
                                                          )
                                                          :
                                                          ("")
                                                          */}
                                                          
                                                            {/*
                                                              this.state.BGAccess.id == "1" || this.state.BGAccess.id == "2" || this.state.BGAccess.id == "3" || this.state.BGAccess.id == "4" || this.state.BGAccess.id == "19"  ?
                                                              (
                                                                <Link to={{
                                                                  pathname: "/send_voter_slip",
                                                                        params: {
                                                                          navbarDisplay:"show",
                                                                          userAccessList:this.state.userAccessListArr,
                                                                        }
                                                                      }} onClick={() => { this.showSidebar()}}>
                                                                    <MenuItem label="Voter Slip & Poll Management" subMenuItems={[]} icon="fa-solid fa-person-booth" newBlock="new"   />
                                                              </Link>
                                                              )
                                                              :
                                                              ("")
                                                            */}
    
                                                              <Link to={{
                                                                  pathname: "/bsbg_shankharavam_new",
                                                                  params: {
                                                                    navbarDisplay:"hide",
                                                                    userAccessList:this.state.userAccessListArr,
                                                                    campaignId:7
                                                                  }
                                                                }} onClick={() => { this.showSidebar()}}>
                                                                  <MenuItem label="100 DAYS DTD CAMPAIGN" subMenuItems={[]} icon="fa-solid fa-address-card" newBlock=""   />
                                                              </Link>
                                                            
    
                                                            {/*
                                                              this.state.chatID == "5129193582" || this.state.chatID == "943827154" || this.state.chatID == "6899079589" || this.state.chatID == "1048868711" ||
                                                              this.state.chatID == "1032335009" || this.state.chatID == "1343549617" || this.state.chatID == "2145683358"  ?
                                                              (
                                                                <>
                                                                {/*<Link to={{
                                                                        pathname: "/voter_slip_campaign",
                                                                        params: {
                                                                          navbarDisplay:"show",
                                                                          userAccessList:this.state.userAccessListArr,
                                                                          campaignId:4
                                                                        }
                                                                      }} onClick={() => { this.showSidebar()}}>
                                                                        <MenuItem label="Voter Slip Distributed Campaign" subMenuItems={[]} icon="fa-solid fa-square-poll-vertical" newBlock="new"   />
                                                              </Link>
    
                                                              <Link to={{
                                                                pathname: "/voter_status_campaign",
                                                                params: {
                                                                  navbarDisplay:"show",
                                                                  userAccessList:this.state.userAccessListArr,
                                                                  campaignId:5
                                                                }
                                                              }} onClick={() => { this.showSidebar()}}>
                                                                <MenuItem label="Polled Status Campaign" subMenuItems={[]} icon="fa-solid fa-square-poll-vertical" newBlock="new"   />
                                                            </Link>}
    
                                                                <Link to={{
                                                                  pathname: "/exit_poll_campaign",
                                                                  params: {
                                                                    navbarDisplay:"show",
                                                                    userAccessList:this.state.userAccessListArr,
                                                                    campaignId:6
                                                                  }
                                                                }} onClick={() => { this.showSidebar()}}>
                                                                  <MenuItem label="Exit Poll Campaign" subMenuItems={[]} icon="fa-solid fa-square-poll-vertical" newBlock="new"   />
                                                                </Link>
                                                                </>
                                                              )
                                                              :
                                                              ("")
                                                            */ }
    
                                                            {
                                                              this.state.chatID == "5129193582" || this.state.chatID == "943827154"  || this.state.chatID == "6431718935" || this.state.chatID == "1048868711"  || 
                                                              
                                                              consMLCIdsArr.some(item => item == this.state.userAccessListArr.constituencyList[0].id)
                                                              ?
                                                              (
                                                                <Link to={{
                                                                  pathname: "/mlc_voters",
                                                                  params:{
                                                                    userAccessList:this.state.userAccessListArr,
                                                                  }
                                                                }} onClick={() => { this.showSidebar()}}>
                                                                <MenuItem label="MLC Voters Verification" subMenuItems={[]} icon="fa-solid fa-file-circle-plus" newBlock="new"  />
                                                              </Link>
                                                              )
                                                              :
                                                              ("")
                                                            }
    
                                                            
    
    
                                                            {
                                                              this.state.chatID == "5129193582" || this.state.chatID == "943827154" || this.state.chatID == "6899079589" || this.state.chatID == "1048868711" ||
                                                              this.state.chatID == "1032335009" || this.state.chatID == "1343549617" || this.state.chatID == "2145683358" ||  this.state.chatID == "401840605" ?
                                                              (
                                                                <Link to={{
                                                                        pathname: "/election_activities",
                                                                        params: {
                                                                          navbarDisplay:"show",
                                                                          userAccessList:this.state.userAccessListArr,
                                                                        }
                                                                      }} onClick={() => { this.showSidebar()}}>
                                                                        <MenuItem label="అభ్యర్థి ప్రచార కార్యక్రమాలు" subMenuItems={[]} icon="fa-solid fa-square-poll-vertical" newBlock=""   />
                                                              </Link>
                                                              )
                                                              :
                                                              ("")
                                                            }
    
                                                            {
                                                              this.state.BGAccess.id == "1" || this.state.BGAccess.id == "2" || this.state.BGAccess.id == "3" || this.state.BGAccess.id == "4" ?
                                                              (
                                                                <>
                                                                  <Link to={{
                                                                                  pathname: "/my_team",
                                                                                  params: {
                                                                                    navbarDisplay:"show",
                                                                                    userAccessList:this.state.userAccessListArr,
                                                                                    accessName:this.state.accessName,
                                                                                    roleName:this.state.roleName,
                                                                                    roleId:this.state.BGAccess.id
                                                                                  }
                                                                                }} onClick={() => { this.showSidebar()}}>
                                                                                  <MenuItem label="MY CUBS" subMenuItems={[]} icon="fa-solid fa-people-group" newBlock=""   />
                                                                        </Link>
                                                                </>
                                                              )
                                                              :
                                                              ("")
                                                            }
    
                                                            
    
                                                           
    
    
                                                         {/* <Link to={{
                                                              pathname: "/voters_migration",
                                                                  params: {
                                                                    navbarDisplay:"show",
                                                                    userAccessList:this.state.userAccessListArr,
                                                                  }
                                                                }} onClick={() => { this.showSidebar()}}>
                                                                  <MenuItem label="Migrated Voters" subMenuItems={[]} icon="fa-solid fa-file-circle-plus" newBlock=""  />
                                                          </Link>*/}
                                                        
                                                          {
                                                            this.state.chatID == "5129193582" || this.state.chatID == "6899079589" || this.state.chatID == "943827154"
                                                          ?
                                                            (
                                                                <Link to={{
                                                                  pathname: "/publicOutReach_new",
                                                                      params: {
                                                                        navbarDisplay:"hide",
                                                                        userAccessList:this.state.userAccessListArr,
                                                                        campaignId:2
                                                                      }
                                                                    }} onClick={() => { this.showSidebar()}}>
                                                                      <MenuItem label="Public OutReach" subMenuItems={[]} icon="fa-solid fa-address-card" newBlock=""  />
                                                              </Link>
                                                            )
                                                            :
                                                            ("")
                                                          }
                                                          {/*
                                                            this.state.chatID == "5129193582" || this.state.chatID == "943827154"  || this.state.chatID == "1048868711"
                                                            ||  this.state.chatID == "6899079589"  
                                                            
                                                            ?
                                                            (
                                                              <>
                                                                
                                                                  <Link to={{
                                                                    pathname: "/bsbg_publicOutreach",
                                                                    params: {
                                                                      navbarDisplay:"hide",
                                                                      userAccessList:this.state.userAccessListArr,
                                                                    }
                                                                  }} onClick={() => { this.showSidebar()}}>
                                                                    <MenuItem label="Public Outreach" subMenuItems={[]} icon="fa-solid fa-file-pen"   />
                                                                  </Link>
                                                                  
                                                                    <Link to={{
                                                                          pathname: "/qr_attendence",
                                                                          params: {
                                                                            navbarDisplay:"show",
                                                                            userAccessList:this.state.userAccessListArr,
                                                                          }
                                                                        }} onClick={() => { this.showSidebar()}}>
                                                                          <MenuItem label="Attendence" subMenuItems={[]} icon="fa-solid fa-clipboard-user"   />
                                                                    </Link>
    
                                                                </>
                                                            )
                                                            :
                                                            ("")
                                                                      */}
                                                          
                                                          {/*<Link to={{
                                                              pathname: "/generate_qrCode",
                                                              params: {
                                                                navbarDisplay:"show",
                                                                userAccessList:this.state.userAccessListArr,
                                                              }
                                                            }} onClick={() => { this.showSidebar()}}>
                                                              <MenuItem label="My QR Code" subMenuItems={[]} icon="fa-solid fa-qrcode"   />
                                                          </Link>*/}
    
                                                      {
                                                          this.state.chatID == "5129193582" || this.state.chatID == "943827154"  || this.state.chatID == "1048868711"
                                                          || this.state.chatID == "1032335009" || this.state.chatID == "966069833" 
                                                          ||  this.state.chatID == "1343549617" || this.state.chatID == "2145683358" 
                                                          || this.state.chatID == "6899079589" || this.state.chatID == "5098148337"  
                                                          ?
                                                          (
                                                            <Link to={{
                                                              pathname: "/leader_search_page",
                                                              params: {
                                                                navbarDisplay:"hide",
                                                              }
                                                            
                                                            }} onClick={() => { this.showSidebar()}}>
                                                            <MenuItem label="Leaders Search" subMenuItems={[]} icon="fa-brands fa-searchengin" newBlock=""  />
                                                          </Link>
                                                          )
                                                          :
                                                          ("")
                                                          }
    
                                                        {/*
                                                        this.state.chatID == "5129193582" || this.state.chatID == "943827154"  || this.state.chatID == "1048868711"
                                                                || this.state.chatID == "1032335009" || this.state.chatID == "966069833" || this.state.chatID == "339366859"
                                                                ||  this.state.chatID == "1343549617" || this.state.chatID == "2145683358" || this.state.chatID == "401840605"
                                                                || this.state.chatID == "6899079589" 
                                                          ?
                                                          (
                                                            <Link to={{
                                                              pathname: "/volunteer_page_location",
                                                              params: {
                                                                navbarDisplay:"hide",
                                                                userAccessList:this.state.userAccessListArr,
                                                              }
                                                            }} onClick={() => { this.showSidebar()}}>
                                                              <MenuItem label="Booth Wise Nearest Places" subMenuItems={[]} icon="fa-solid fa-address-card"   />
                                                            </Link>
                                                          )
                                                          :
                                                          ("")
                                                          */ }
    
                                                        {/* <Link to={{
                                                              pathname: "/voters_forms_verification",
                                                              params: {
                                                                userAccessList:this.state.userAccessListArr,
                                                              }
                                                            }} onClick={() => { this.showSidebar()}}>
                                                              <MenuItem label="Voters Forms Verification" subMenuItems={[]} icon="fa-solid fa-address-card" newBlock=""  />
                                                            </Link>
    
                                                          */}
                                                        
                                                        {/*<Link to={{
                                                            pathname: "/raiseIssue",
                                                          
                                                          }} onClick={() => { this.showSidebar()}}>
                                                          <MenuItem label="Raise An Issue" subMenuItems={[]} icon="fa-solid fa-users" newBlock="" />
                                                        </Link>*/}
                                                        <Link to={{
                                                          pathname: "/first_time_voter_search",
                                                          params:{
                                                            userAccessList:this.state.userAccessListArr,
                                                          }
                                                        }} onClick={() => { this.showSidebar()}}>
                                                        <MenuItem label="First Time Voter Search" subMenuItems={[]} icon="fa-solid fa-magnifying-glass"  newBlock="" />
                                                      </Link>
                                                        <Link to={{
                                                            pathname: "/voter_search",
                                                            params:{
                                                              userAccessList:this.state.userAccessListArr,
                                                            }
                                                          }} onClick={() => { this.showSidebar()}}>
                                                          <MenuItem label="Voter Search" subMenuItems={[]} icon="fa-solid fa-magnifying-glass" newBlock=""  />
                                                        </Link>
                                                        
                                                        {/*
                                                          this.state.BGAccess.id == "1" || this.state.BGAccess.id == "2" || this.state.BGAccess.id == "3" || this.state.BGAccess.id == "5" || 
                                                          this.state.BGAccess.id == "6"   ?
                                                          (
                                                            <Link to={{
                                                                pathname: "/performance_report"
                                                              }} onClick={() => { this.showSidebar()}}>
                                                              <MenuItem label="Reports" subMenuItems={[]} icon="fa-solid fa-magnifying-glass-chart" newBlock="" />
                                                            </Link>
                                                          )
                                                          :
                                                          ("")
                                                        */}
                                                        {/*<Link to={{
                                                                pathname: "/election_campaign"
                                                            }}>
                                                            <li><i class="fa-solid fa-square-poll-vertical me-2"></i> Door To Door Campaign</li>
                                                        </Link>
                                                    <Link to={{
                                                                pathname: "/poll_manag_panch_elec"
                                                            }}>
                                                            <li><i class="fa-solid fa-square-poll-vertical me-2"></i> Poll Management</li>
                                                        </Link>
                                                        <Link to={{
                                                                pathname: "/ward_campaign"
                                                            }}>
                                                            <li><i class="fa-solid fa-person-shelter me-2"></i> Ward Campaign Status Update</li>
                                                        </Link>
                                                        <Link to={{
                                                            pathname: "/voters_list_revision",
                                                            params:{
                                                              userAccessList:this.state.userAccessListArr
                                                            }
                                                          }}>
                                                          <li className="ln_20"><i class="fa-solid fa-house-user me-2"></i>EC House to House Voters - Revision - Status Update </li>
                                                        </Link>*/}
                                                        </>
                                                        )
                                                      )
                                                    )
                                                  
                                                  )
                                                )
                                                
                                              )
                                            )
                                          )
                                        )
                                      )
                                      
                                    )
                                    
                                  )
                                )
                              )
                              :
                              ("")
                            )
                            :
                            (
                              this.state.attrVerfiDtsCheck.length > 0 ?
                              (
                                <>
                                      <Link to={{
                                              pathname: "/voters_collections",
                                              params: {
                                                userAccessList:this.state.userAccessListArr,
                                                attrVerfiDtsCheck:this.state.attrVerfiDtsCheck
                                              }
                                          }} onClick={() => { this.showSidebar()}}>
                                          <MenuItem label="Attributes Verification" subMenuItems={[]} icon="fa-solid fa-file-circle-check" newBlock="" />
                                      </Link>
                                      <Link to={{
                                              pathname: "/voters_collections_dashboard",
                                              params: {
                                                userAccessList:this.state.userAccessListArr,
                                                attrVerfiDtsCheck:this.state.attrVerfiDtsCheck
                                              }
                                          }} onClick={() => { this.showSidebar()}}>
                                          <MenuItem label="Verification - Dashboard" subMenuItems={[]} icon="fa-solid fa-file-circle-check" newBlock="" />
                                        </Link>
                                  </>
                              )
                              :
                              ("")
                            )
                          }
                          <Link to={{
                                pathname: "/social_media",
                                params: {
                                  navbarDisplay:"hide",
                                }
                                
                              }} onClick={() => { this.showSidebar()}}>
                              <MenuItem label="Social Media" subMenuItems={[]} icon="fa-solid fa-hashtag" newBlock=""  />
                          </Link>

                            
                      </ul>
                      
                  </nav>
                </div>
          </section>
        </>
    )
  }
}


export default withRouter(NavBar);
